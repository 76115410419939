/* JQUERY */
import "../node_modules/jquery/dist/jquery.js"
import "../node_modules/jquery-lazy/jquery.lazy.js"
import "../node_modules/jquery-lazy/jquery.lazy.plugins.js"

/* FANCYBOX */
import "../node_modules/fancybox/dist/js/jquery.fancybox.js"
import "../node_modules/fancybox/dist/helpers/js/jquery.fancybox-buttons.js"
import "../node_modules/fancybox/dist/helpers/js/jquery.fancybox-thumbs.js"
import "../node_modules/fancybox/dist/helpers/js/jquery.fancybox-media.js"
import "../node_modules/fancybox/dist/css/jquery.fancybox.css"

/* EASYZOOM */
import "../node_modules/easyzoom/dist/easyzoom.js"
import "../node_modules/easyzoom/css/easyzoom.css"

/* SLICK */
import "../node_modules/slick-carousel/slick/slick.js"
import "../node_modules/slick-carousel/slick/slick.css"
import "../node_modules/slick-carousel/slick/slick-theme.css"
